<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    {{ editable ? "New Info" : "Add Info" }}

    <v-form v-model="isValid">
      {{/* List here the added cards */}}

      <!--  <p class="body-1" v-text="$t('cardNumber')"></p>
      <v-text-field
        v-model="cardNumber"
        class="rounded-0"
        dense
        outlined
        placeholder="Credit card number"
        :rules="[rules.required, formatNumber, validateCard]"
      />

      <p class="body-1" v-text="$t('expirationDate')"></p>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="year"
            class="rounded-0"
            :items="getYears()"
            dense
            outlined
            :rules="[rules.required]"
            :placeholder="$t('year')"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="month"
            class="rounded-0"
            outlined
            dense
            item-text="name"
            item-value="value"
            :placeholder="$t('month')"
            :items="months"
            :rules="[rules.required]"
          ></v-select>
        </v-col>
      </v-row>

      <p class="body-1" v-text="$t('cvc')"></p>
      <v-text-field
        v-model="cvc"
        class="rounded-0"
        dense
        outlined
        :rules="[rules.required, formatCvc]"
        placeholder="CVC"
      /> -->
      <p class="body-1">Billing Address</p>
      <v-row no-gutters>
        <v-col cols="12" sm="8">
          <v-text-field
            outlined
            v-model="billingAddress.addressLine1"
            dense
            label="Address"
            class="rounded-0"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" class="px-1">
          <v-text-field
            class="rounded-0"
            outlined
            v-model="billingAddress.addressLine2"
            dense
            label="Apto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            :items="states"
            v-model="state"
            item-text="name"
            item-value="id"
            autocomplete="off"
            label="State"
            :loading="loadingStates"
            outlined
            dense
            class="rounded-0"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" class="px-1">
          <v-autocomplete
            :disabled="stateCities.length == 0"
            :items="stateCities"
            item-text="name"
            item-value="id"
            :loading="loadingCities"
            autocomplete="off"
            label="City"
            v-model="billingAddress.city"
            mandatory
            outlined
            dense
            class="rounded-0"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            class="rounded-0"
            outlined
            :rules="[rules.isZipCode]"
            v-model="billingAddress.zipCode"
            dense
            label="Zip Code"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn
        class="white--text"
        block
        depressed
        tile
        color="grey darken-4"
        large
        :disabled="!isValid"
        @click="addCard"
        :loading="loading"
      >
        {{ $t("confirm") }}
      </v-btn>
      <template v-if="editable">
        <v-btn
          class="white--text mt-2"
          block
          depressed
          tile
          color="red darken-4"
          large
          @click="cancelAll"
          :loading="loading"
        >
          {{ $t("Cancel") }}
        </v-btn>
      </template>
    </v-form>
  </div>
</template>
<script>
import rules from "@/components/account/rules";
import { getAPI } from "@/api/axios-base";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import moment from "moment";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "add-card-client",
  props: {
    editable: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isValid: null,
      rules: rules,
      cardNumber: "",
      month: "",
      year: "",
      cvc: "",
      state: 0,
      stateCities: [],
      loadingCities: false,
      billingAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
      },
      formatNumber: (v) => /^[0-9]+$/.test(v) || this.$t("formatError"),
      validateYear: (v) => {
        if (v == null) {
          return "";
        }

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const userYear = parseInt(v);

        if (v.length != 4) {
          return this.$t("lengthYearError");
        }

        if (userYear > currentYear) {
          return true;
        }

        return this.$t("yearWrong");
      },
      formatCvc: (v) =>
        /^[0-9][0-9]{2,3}$/.test(v) || this.$t("formatCvcError"),

      validateCard: (v) => {
        const isCard =
          /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

        return isCard.test(v) || this.$t("wrongCard") + "";
      },

      loading: false,
    };
  },
  watch: {
    state: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }
      this.billingAddress.state = stateId;
      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities = false;
        this.stateCities = cities;
      });
    },
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail"]),
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    months() {
      return [
        {
          value: 1,
          name: this.$t("January"),
        },
        {
          value: 2,
          name: this.$t("February"),
        },
        {
          value: 3,
          name: this.$t("March"),
        },
        {
          value: 4,
          name: this.$t("April"),
        },
        {
          value: 5,
          name: this.$t("May"),
        },
        {
          value: 6,
          name: this.$t("June"),
        },
        {
          value: 7,
          name: this.$t("July"),
        },
        {
          value: 8,
          name: this.$t("August"),
        },
        {
          value: 9,
          name: this.$t("September"),
        },
        {
          value: 10,
          name: this.$t("October"),
        },
        {
          value: 11,
          name: this.$t("November"),
        },
        {
          value: 12,
          name: this.$t("December"),
        },
      ];
    },
  },
  methods: {
    ...mapMutations("crmMedicFormModule", ["mutPatientDetails"]),
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
    addCard() {
      const uuid = this.$route.params.uuid;
      /*  let body = {
        cardExpireDate: "",
        cvv: this.cvc,
        cardNumber: this.cardNumber,
      };
      body.cardExpireDate = this.getExpiration(
        this.month,
        this.year.toString()
      ); */

      if (
        this.billingAddress.addressLine1 != null &&
        this.billingAddress != ""
      ) {
        body = {
          ...body,
          billingAddress: {
            addressLine1: this.billingAddress.addressLine1,
            addressLine2: this.billingAddress.addressLine2,
            city: this.billingAddress.city.toString(),
            state: this.billingAddress.state.toString(),
            zipCode: this.billingAddress.zipCode,
          },
        };
      }

      this.loading = true;

      getAPI
        .put(`/patient/updatePatient/${uuid}`, body)
        .then((res) => {
          this.loading = false;
          notifySuccess("Billing Address has been change");

          this.getPatientData();
          this.cancelAll();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    async getPatientData() {
      this.loading = true;

      const patient = (
        await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
      ).data;

      this.mutPatientDetails(patient);

      this.loading = false;
    },
    getExpiration(month, year) {
      const date = moment(year);
      date.startOf("year").add(month - 1, "month");

      return date.toISOString();
    },

    getYears() {
      const yearsList = [];
      const currentYear = new Date().getFullYear();

      for (let i = 1; i <= 10; i++) {
        yearsList.push(currentYear + i);
      }

      return yearsList;
    },

    cancelAll() {
      this.cardNumber = "";
      this.month = "";
      this.year = "";
      this.cvc = "";
      this.$emit("edited");
    },
  },

  mounted() {
    this.actListStates();
  },

  destroyed() {
    this.cancelAll();
  },
};
</script>
<style lang="scss" scoped></style>
<i18n>
{
  "en": {
    "title": "Add a credit card",
    "cardNumber": "Credit card number",
    "cardHolder": "Card Holder",
    "expirationDate": "Expiration date",
    "cvc": "Card verification code (CVC)",
    "addCard": "Add card",
    "confirm": "Confirm",
    "year": "Year",
    "month": "Month",
    "formatError": "Wrong format, type a number",
    "yearWrong": "The year provided have to major to current year",
    "lengthYearError": "The year must have four characters",
    "wrongCard": "You must use one of the following cards: Visa, MasterCard, American Express, Diners Club, Discover or JCB",
    "formatCvcError": "Invalid CVC, only 3 or 4 digits are accepted",
    "cardSelected": "The card has been added, select it from the list",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    "addOne": "Card Data",
    "epayaddOne": "USAePay Card Data"
  },
  "es": {
    "title": "Agregar tarjeta de crédito",
    "cardNumber": "Número de tarjeta de crédito",
    "expirationDate": "Fecha de expiración",
    "cvc": "CVC inválido, solo se aceptan 3 o 4 dígitos",
    "addCard": "Agregar tarjeta",
    "confirm": "Confirmar",
    "year": "Año",
    "month": "Mes",
    "formatError": "Formato erróneo, escriba un número",
    "yearWrong": "El año proporcionado debe ser mayor al año actual.",
    "lengthYearError": "El año debe tener cuatro caracteres",
    "wrongCard": "Debe usar una de las siguientes tarjetas: Visa, MasterCard, American Express, Diners Club, Discover o JCB",
    "formatCvcError": "CVC inválido",
    "cardSelected": "La tarjeta ha sido añadida, selecciónala de la lista",
    "January": "Enero",
    "February": "Febrero",
    "March": "Marzo",
    "April": "Abril",
    "May": "Mayo",
    "June": "Junio",
    "July": "Julio",
    "August": "Agosto",
    "September": "Septiebre",
    "October": "Octubre",
    "November": "Noviembre",
    "December": "Diciembre",
    "addOne": "Datos de la tarjeta",
     "epayaddOne": "Datos de la tarjeta USAePay"
  }
}
</i18n>
