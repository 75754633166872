
/**
 * @description To take the user cards
 */
import Vue from "vue";

import rules from "@/components/account/rules";
import { TranslateResult } from "vue-i18n";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { getAPI } from "@/api/axios-base";
import IconVisa from "@/components/icons/iconVisa.vue";
import IconMastercard from "@/components/icons/iconMastercard.vue";
import * as CONFIG from "@/enviorment";
import { mapGetters, mapMutations, mapState } from "vuex";
export default Vue.extend({
  components: {
    IconVisa,
    IconMastercard,
  },
  name: "patient-card",

  provide() {
    return {
      cardData: (this as any).toProvide,
    };
  },
  data() {
    return {
      tab: null,
      isValid: null,
      isValid2: null,
      cardSelected: null,
      epaycard: false,
      rules: rules,
      cardNumber: "",
      month: "",
      year: "",
      cvc: "",
      epayYear: "",
      epayMonth: "",
      usaEpay: {
        method_name: "",
        cardholder: "",
        number: "",
        cvc: "",
        expiration: "",
        avs_street: "",
        avs_postalcode: "",
        pay_type: "cc",

        default: false,
      },
      cards: [],
      toProvide: {
        cards: [],
        loading: false,
      },

      formatNumber: (v: string): boolean | TranslateResult =>
        /^[0-9]+$/.test(v) || this.$t("formatError"),
      validateYear: (v: string): boolean | TranslateResult => {
        if (v == null) {
          return "";
        }

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const userYear = parseInt(v);

        if (v.length != 4) {
          return this.$t("lengthYearError");
        }

        if (userYear > currentYear) {
          return true;
        }

        return this.$t("yearWrong");
      },
      formatCvc: (v: string) =>
        /^[0-9][0-9]{2,3}$/.test(v) || this.$t("formatCvcError"),

      validateCard: (v: string): boolean | string => {
        const isCard =
          /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

        return isCard.test(v) || this.$t("wrongCard") + "";
      },

      loading: false,
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail", "havecard"]),
    ...mapGetters(["getUseMerchant"]),
    months(): Array<{ value: number; name: TranslateResult }> {
      return [
        {
          value: 1,
          name: this.$t("January"),
        },
        {
          value: 2,
          name: this.$t("February"),
        },
        {
          value: 3,
          name: this.$t("March"),
        },
        {
          value: 4,
          name: this.$t("April"),
        },
        {
          value: 5,
          name: this.$t("May"),
        },
        {
          value: 6,
          name: this.$t("June"),
        },
        {
          value: 7,
          name: this.$t("July"),
        },
        {
          value: 8,
          name: this.$t("August"),
        },
        {
          value: 9,
          name: this.$t("September"),
        },
        {
          value: 10,
          name: this.$t("October"),
        },
        {
          value: 11,
          name: this.$t("November"),
        },
        {
          value: 12,
          name: this.$t("December"),
        },
      ];
    },
    // Check if the card have been added
    cardAdded(): boolean {
      let cardAdded = false;

      if (
        (this as any).cardNumber == null ||
        (this as any).cardNumber == undefined
      ) {
        return false;
      }
      (this as any).toProvide.cards.forEach((card: any) => {
        if ((this as any).cardNumber.endsWith((card as any).last4)) {
          cardAdded = true;
        }
      });

      return cardAdded;
    },
    cardEpayAdded(): boolean {
      let cardAdded = false;

      if (
        (this as any).usaEpay.number == null ||
        (this as any).usaEpay.number == undefined ||
        (this as any).usaEpay.number == ""
      ) {
        return false;
      }
      (this as any).toProvide.cards.forEach((card: any) => {
        if ((this as any).cardNumber.endsWith((card as any).last4)) {
          cardAdded = true;
        }
      });

      return cardAdded;
    },
  },
  methods: {
    ...mapMutations("crmMedicFormModule", [
      "mutHaveCard",
      "mutPatientDetails",
      "mutepayCard",
    ]),
    addother() {
      (this as any).cardSelected = null;
      this.$emit("cardid", "");
    },
    selectedCard(item: any) {
      (this as any).cardSelected = item;
      (this as any).epaycard = false;
      (this as any).mutepayCard(false);
      this.$emit("cardid", item.id);
    },
    selectedEpay(item: any) {
      (this as any).cardSelected = item;
      (this as any).epaycard = true;
      (this as any).mutepayCard(true);
      this.$emit("cardid", item.uuid);
    },
    async addCard(): Promise<void> {
      var myHeaders = new Headers();
      const url = "https://api.stripe.com/v1/tokens";

      myHeaders.append("Authorization", `Bearer ${CONFIG.stripePublicKey}`);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("card[number]", (this as any).cardNumber);
      urlencoded.append("card[exp_month]", (this as any).month);
      urlencoded.append("card[exp_year]", (this as any).year);
      urlencoded.append("card[cvc]", (this as any).cvc);

      (this as any).loading = true;
      let ERROR_EN = "";
      let ERROR_ES = "";
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        });
        const resStatus = res.status;

        if (resStatus == 200) {
          const cardData = await res.json();
          const token = cardData.id;
          const cardId = cardData.card.id;
          (this as any).cardSelected = cardData.card;
          const createdCard = await getAPI.put(
            `/patient/link-card/${(this as any).patientDetail.uuid}/${token}`
          );
          const pat = (
            await getAPI(
              `/patient/getPatient/${(this as any).patientDetail.uuid}`
            )
          ).data;
          (this as any).mutPatientDetails(pat);
          (this as any).mutepayCard(false);
          (this as any).mutHaveCard(true);
          this.$emit("cardid", cardId);
          (this as any).loading = false;
        }

        if (resStatus >= 400) {
          const errorData = await res.json();

          if (errorData.error.code == "incorrect_number") {
            ERROR_EN = "The credit card number is wrong";
            ERROR_ES = "El número de tarjeta de crédito es incorrecto";
          } else if (errorData.error.code == "invalid_cvc") {
            ERROR_EN = "Card verification code (CVC) is wrong";
            ERROR_ES =
              "El Código de Verificación de la Tarjeta (CVC) es incorrecto";
          } else if (errorData.error.code == "card_declined") {
            ERROR_EN = errorData.error.message;

            ERROR_ES =
              "Su tarjeta fue rechazada, verifique los datos sean los correptos.";
          } else {
            ERROR_EN =
              "An error occurred while the operation was being carried out, verify that you have an internet connection or are using a badly configured proxy or VPN";
            ERROR_ES =
              "Ocurrió un error mientras se realizaba la operación, verifica que tienes conexión a internet o estás usando un proxy o VPN mal configurado";
          }
          (this as any).loading = false;
          notifyError(ERROR_EN);
        } else {
          notifySuccess("Credit Card Accept");
        }
      } catch (error: any) {
        (this as any).loading = false;

        let mess = error.response.data.message.toString();

        notifyError(error.response.data, `An error occurred: ${mess}`);
      }
    },

    async addCardEpay() {
      (this as any).loading = true;
      const uuid = this.$route.params.uuid;
      const body = Object.assign({}, (this as any).usaEpay);
      body.expiration = (this as any).getExpiration(
        (this as any).epayMonth,
        (this as any).epayYear.toString()
      );
      try {
        const resp = (await getAPI.put("/patient/addUsaEpayCard/" + uuid, body))
          .data;
        (this as any).loading = false;
        (this as any).mutHaveCard(true);
        const pat = (
          await getAPI(
            `/patient/getPatient/${(this as any).patientDetail.uuid}`
          )
        ).data;
        (this as any).mutPatientDetails(pat);
        (this as any).selectedEpay(resp);

        (this as any).resetAll();
      } catch (error: any) {
        (this as any).loading = false;
        let mess = error.response.data.message.toString();

        notifyError(error.response.data, `An error occurred: ${mess}`);
      }
    },
    getExpiration(month: string, year: string) {
      let m = "";
      let y = "";
      if (Number(month) < 10) {
        m = "0" + month;
      } else {
        m = month;
      }
      y = year.substr(-2, 2);
      return m + y;
    },
    getYears(): number[] {
      const yearsList: number[] = [];
      const currentYear = new Date().getFullYear();

      for (let i = 1; i <= 10; i++) {
        yearsList.push(currentYear + i);
      }

      return yearsList;
    },

    resetAll() {
      (this as any).epayYear = "";
      (this as any).epayMonth = "";
      (this as any).usaEpay = {
        method_name: "",
        cardholder: "",
        number: "",
        cvc: "",
        expiration: "",
        avs_street: "",
        avs_postalcode: "",
        pay_type: "cc",

        default: false,
      };
    },
  },
});
